import './scss/index.scss';
import {Outlet, useNavigate} from "react-router-dom";
// @ts-ignore
import Maps from "./assets/icon/maps.svg";
import Left from "./assets/icon/left.svg";
import Carbon_location from "./assets/icon/carbon_location-current.svg";
// @ts-ignore

import TapBar from "../../components/tapBar";
import React, {useState, useEffect,useRef} from 'react';

import StateSlices from "../../redux/slices/State";
import {useSelector} from "react-redux";
import StateActions from "../../redux/actions/StateActions";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);
    const citySelect = (state_in.city_select);
    const bgAll = (state_in.bgAll);
    const bgSelect = (state_in.bgSelect);

    const previousOrientation = useRef(false);

    const navigate = useNavigate();


    const [heading, setHeading] = useState(0);

    let elBG = bgAll.find(bg => bg.id === bgSelect)
    if (!elBG) {
        elBG = bgAll[0]
    }

    // Функция для обработки изменения направления
    const handleDirectionChange = (direction) => {
        setHeading(direction);
    };

    // useEffect для обновления направления на основе якоря в URL
    useEffect(() => {
        const handleHashChange = () => {
            // Получаем значение якоря из URL, например, #direction=90
            const hashValue = window.location.hash;
            // Проверяем, содержит ли якорь значение направления
            if (hashValue && hashValue.includes('direction')) {
                // Извлекаем значение направления из якоря
                const direction = parseInt(hashValue.split('=')[1])-180;
                // Вызываем функцию для обновления направления
                handleDirectionChange(direction);
            }
        };

        // Устанавливаем обработчик события для события hashchange
        window.addEventListener('hashchange', handleHashChange);

        // Вызываем обработчик якоря при загрузке страницы
        handleHashChange();

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <div className={'page page-bk'} style={{backgroundImage: "url(data:image/png;base64," + elBG?.images + ")"}}>
            <div className="page__main-content">

                <div className="page-bk-head">
                    <p onClick={() => {
                        navigate('/time');
                    }} className={'text icon-center-pos text-s15'}>
                        <img onClick={() => {

                        }} alt={'maps'} className={'page-icon page-icon-mini'} src={Left}/>
                        <span className={'text text-s22'}>Кибла</span>
                    </p>
                    <img  onClick={() => {

                    }} alt={'maps'} className={'page-icon'} src={Carbon_location}/>
                </div>

                <div class="compos-element">
                    <img onClick={() => {

                    }} alt={'maps'} style={{transition:"0.3s",transform: "rotate(" + heading + "deg)"}} className={'icon-compos-cursor'} src={Maps}/>
                </div>


                <TapBar/>
                <Outlet/>

            </div>
        </div>
    );

}