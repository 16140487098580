const INITIAL_STATE = {
    city_select: {},
    bgAll: [],
    bgAll: [],
    allDateTimeCity: [],
    articleOpen: {
        name: '',
        time: '',
        content: '',
    },
    bgSelect: 1,
    currentPage: '/',
    userName: '',
    long: 0,
    lat: 0,
    lang: 'ru',
};


const stateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'STATE':
            return {...state, ...action.value};
        default:
            return state;
    }
};
export default stateReducer;
