import './scss/index.scss';
import {Outlet} from "react-router-dom";
// @ts-ignore
import Bk1 from "./assets/img/bk1.png";
// @ts-ignore
import Fe_picture from "./assets/icon/fe_picture.svg";

import TapBar from "../../components/tapBar";

import moment from 'moment';

import StateSlices from "../../redux/slices/State";
import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateActions from "../../redux/actions/StateActions";
import {useEffect, useState} from "react";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFormattedDate() {
    const date = new Date();
    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

    return capitalizeFirstLetter(date.toLocaleDateString('ru-RU', options));
}


function convertDates(cityTime) {
    return (getTimeLeft(cityTime));
}


function getTimeDifference(date1, date2) {
    let difference = date2 - date1;
    let seconds = Math.floor(difference / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds %= 60;
    minutes %= 60;
    return (hours > 0 ? hours.toString().padStart(2, '0') + ':' : '') + (minutes > 0 ? minutes.toString().padStart(2, '0') + ':' : '') + seconds.toString().padStart(2, '0');
}

function getTimeLeft(cityTime) {
    const now = new Date();

    const dayLengh = moment();
    const day = now.getDate();

    // Получаем текущий день года
    const currentDay = cityTime[dayLengh.dayOfYear() - 1];
    let arrayTimeReturn = [];


    let currentSet = true;
    for (var i = 0; i < currentDay?.length; i++) {
        const prayerTime = new Date(currentDay[i][1]);
        const hours = prayerTime.getHours().toString().padStart(2, '0');
        const minutes = prayerTime.getMinutes().toString().padStart(2, '0');
        let currentTimeSet = '';


        if (prayerTime > now && currentSet) {
            currentTimeSet = (getTimeDifference(now, prayerTime));
            currentSet = false;
        } else {
        }
        arrayTimeReturn.push([currentDay[i][0], hours + ":" + minutes, currentTimeSet === '' ? false : currentTimeSet, currentDay[i][5]]);
    }
    return arrayTimeReturn;
}

export default function AboutRoute() {

    const state_in = useSelector(StateSlices);
    const citySelect = (state_in.city_select);
    const bgAll = (state_in.bgAll);
    const bgSelect = (state_in.bgSelect);
    const allDateTimeCity = (state_in.allDateTimeCity);

    const [resetPage, setResetPage] = useState(false);
    let elBG = bgAll.find(bg => bg.id === bgSelect)

    if (!elBG) {
        elBG = bgAll[0]
    }


    useEffect(() => {
        const handleHashChange = () => {
            // Получаем значение якоря из URL, например, #direction=90
            const hashValue = window.location.hash;
            // Проверяем, содержит ли якорь значение направления
            if (hashValue && hashValue.includes('lat') && hashValue.includes('long')) {


                // Извлекаем значение направления из якоря
                const direction = (hashValue.split('&'));
                const lat = (parseFloat(direction[1].split('=')[1]));
                const long = (parseFloat(direction[2].split('=')[1]));

                if (long > 0 && long > 0) {
                    State({
                        lat: lat,
                        long: long,
                    })
                }

                // Вызываем функцию для обновления направления
            }
        };

        // Устанавливаем обработчик события для события hashchange
        window.addEventListener('hashchange', handleHashChange);

        // Вызываем обработчик якоря при загрузке страницы
        handleHashChange();

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setResetPage(!resetPage);
        }, 1000);
    }, [resetPage]);

    return (
        <div className={'page page-bk'}
             style={{backgroundImage: "url(data:image/png;base64," + elBG?.images + ")"}}>
            <div className="page__main-content">

                <div className="page-bk-head">
                    <p className={'text text-s15'}>
                        {citySelect?.name} <br/>
                        {getFormattedDate()}
                    </p>

                    <img onClick={() => {

                        const {bgAll, bgSelect} = state_in;
                        const currentIndex = bgAll.findIndex(bg => bg.id === bgSelect);

                        // Если текущий id не найден, или это последний элемент, используем первый элемент
                        if (currentIndex === -1 || currentIndex === bgAll.length - 1) {
                            StateActions({bgSelect: bgAll[0].id});
                        }
                        // Иначе используем следующий элемент
                        else {
                            StateActions({bgSelect: bgAll[currentIndex + 1].id});
                        }

                    }} alt={'Изменить фон'} className={'page-icon'} src={Fe_picture}/>
                </div>

                <div className="page-bk-text">
                    <p className={'text text-inter text-s13'}>
                        «Кто постился в Рамадан с верой и надеждой на вознаграждение, тому будут прощены совершенные
                        ранее
                        грехи»
                        (Аль-Бухари, Муслим)
                    </p>
                </div>

                <div className="page-time">

                    <div class="page-time__body">
                        {
                            convertDates(allDateTimeCity).map((timeEl) => {
                                if (!timeEl[3]) {
                                    return null;
                                }
                                return (
                                    <div className={'page-time-el text text-inter text-s14 ' + (timeEl[2] && 'active')}>
                                        <span>{timeEl[0]}</span>
                                        <span> {timeEl[1]}</span>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        convertDates(allDateTimeCity).map((timeEl) => {
                            if (!timeEl[2]) {
                                return null;
                            }
                            return (
                                <div className={'page-time-footer text text-inter text-s14 '}>
                                    <span>{timeEl[0]}</span>
                                    <span> {timeEl[2]}</span>
                                </div>
                            );
                        })
                    }
                </div>

                <TapBar/>
                <Outlet/>

            </div>
        </div>
    );

}