import {
    Routes,
    Route, useNavigate
} from "react-router-dom";


import {useSelector} from "react-redux";
import {getAllDateTime, getBG, getText, getUserInfo} from "../services";
import LangSlices from "../redux/slices/Lang";
import {useEffect} from "react";
import State_in from "../redux/slices/State";
import FirstScreen from "../views/_index";
import TimeScreen from "../views/time";

import ArticleScreen from "../views/article";
import ArticlesScreen from "../views/articles";

import CategoryScreen from "../views/Category";

import ComposScreen from "../views/compos";
import MenuScreen from "../views/menu";
import StateSlices from "../redux/slices/State";
import StateActions from "../redux/actions/StateActions";

const Router = (() => {

    const lang = useSelector(LangSlices);
    const state_in = useSelector(State_in);

    const navigate = useNavigate();

    async function getBackGround() {

        StateActions({
            bgAll: (await getBG().then()).data
        });
    }

    async function getTimeCityAll() {

        if (state_in?.city_select?.id) {

            StateActions({
                allDateTimeCity: (await getAllDateTime(state_in?.city_select?.id).then())
            });
        }

    }

    useEffect(() => {

        getBackGround();
        getTimeCityAll();

        if (state_in?.city_select?.id) {
            navigate('/time');
        }

    }, [state_in?.city_select]);

    return (
        <Routes>

            <Route path="/" element={<FirstScreen/>}/>
            <Route path="/time" element={<TimeScreen/>}/>

            <Route path="/article/:id" element={<ArticleScreen/>}/>
            <Route path="/articles/:id" element={<ArticlesScreen/>}/>
            <Route path="/category/:id" element={<CategoryScreen/>}/>
            <Route path="/compos" element={<ComposScreen/>}/>
            <Route path="/menu" element={<MenuScreen/>}/>


        </Routes>
    )
})
export default Router;
