import './scss/index.scss';
import {Outlet, useNavigate} from "react-router-dom";
import mapsPoint from "./assets/icon/mapsPoint.svg";
import setting from "./assets/icon/setting.svg";
import backImg from "./assets/img/back.svg";
import isha from "./assets/img/Isha.png";
import point from "./assets/icon/point.svg";

import right from "./assets/icon/right.svg";

import TapBar from "../../components/tapBar";


import StateSlices from "../../redux/slices/State";
import {useSelector} from "react-redux";
import StateActions from "../../redux/actions/StateActions";
import {useEffect, useState} from "react";

import gradient from "./assets/img/gradient.svg";
import moment from "moment/moment";
import {conConstGet, getAllCity, getSingle} from "../../services";
import State from "../../redux/actions/StateActions";


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFormattedDate() {
    const date = new Date();
    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

    return capitalizeFirstLetter(date.toLocaleDateString('ru-RU', options));
}


function convertDates(cityTime) {
    return (getTimeLeft(cityTime));
}


function getTimeDifference(date1, date2) {
    let difference = date2 - date1;
    let seconds = Math.floor(difference / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds %= 60;
    minutes %= 60;
    return (hours > 0 ? hours.toString().padStart(2, '0') + ':' : '') + (minutes > 0 ? minutes.toString().padStart(2, '0') + ':' : '') + seconds.toString().padStart(2, '0');
}

function getTimeLeft(cityTime) {
    const now = new Date();

    const dayLengh = moment();
    const day = now.getDate();

    // Получаем текущий день года
    const currentDay = cityTime[dayLengh.dayOfYear() - 1];
    let arrayTimeReturn = [];


    let currentSet = true;
    for (var i = 0; i < currentDay.length; i++) {
        const prayerTime = new Date(currentDay[i][1]);
        const hours = prayerTime.getHours().toString().padStart(2, '0');
        const minutes = prayerTime.getMinutes().toString().padStart(2, '0');
        let currentTimeSet = '';


        if (prayerTime > now && currentSet) {
            currentTimeSet = (getTimeDifference(now, prayerTime));
            currentSet = false;
        } else {
        }
        arrayTimeReturn.push([currentDay[i][0], hours + ":" + minutes, currentTimeSet === '' ? false : currentTimeSet, currentDay[i][5]]);
    }
    return arrayTimeReturn;
}

export default function AboutRoute() {

    const state_in = useSelector(StateSlices);
    const citySelect = (state_in.city_select);
    const bgAll = (state_in.bgAll);
    const bgSelect = (state_in.bgSelect);
    const allDateTimeCity = (state_in.allDateTimeCity);

    const [resetPage, setResetPage] = useState(false);
    const [constryctor, setConstryctor] = useState([]);
    let elBG = bgAll.find(bg => bg.id === bgSelect)

    if (!elBG) {
        elBG = bgAll[0]
    }

    const navigate = useNavigate();

    async function getCity() {


        const cityDate = (await conConstGet(citySelect?.id, state_in.lat, state_in.long)).data;
        setConstryctor(cityDate);
    }


    function getLanguage(inputString) {
        try {
            const jsonObj = JSON.parse(inputString);
            if (jsonObj.lang && jsonObj.lang.ru) {
                return jsonObj.lang.ru;
            } else {
                return inputString;
            }
        } catch (error) {
            return inputString;
        }
    }

    useEffect(() => {
        getCity();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setResetPage(!resetPage);
        }, 1000);
    }, [resetPage]);

    const timeAllCurrent = convertDates(allDateTimeCity);


    const timeControle = convertDates(allDateTimeCity).map((timeEl, indexEl) => {
        if (!timeEl[2]) {
            return false;
        }
        return indexEl;
    }).filter((el) => {
        return el != false;
    });

    let bkCurrent = 0;
    if (timeControle.length > 0) {
        bkCurrent = timeControle[0];
    }

    return (
        <div className={'page page-menu'}>
            <div className="page-menu-content">

                <div className="menu-box">
                    <div className={"menu-box__head namaz-box__body-" + (bkCurrent == 0 ? bkCurrent : bkCurrent - 1)}>
                        <div class="menu-box__top">
                            <p className={'menu-box__top-profile'}>
                                <span className={'text text-inter text-s12'}>Ассаламуалейкум,</span>
                                <span className={'text text-inter text-s26'}>{state_in?.userName}</span>
                            </p>
                        </div>
                        <div class="menu-box__info">
                            <p className={'text text-inter text-s16'}>
                                {citySelect?.name}
                            </p>
                            <br/>
                            <p className={'text text-inter text-s26'}>
                                {
                                    timeAllCurrent.map((timeEl, index) => {

                                        if (!timeEl[2]) {
                                            return null;
                                        }
                                        let currentTime;
                                        if (typeof timeAllCurrent[index - 1] != "undefined") {
                                            currentTime = timeAllCurrent[index - 1];
                                        } else {
                                            currentTime = timeAllCurrent[timeAllCurrent.length - 1];
                                        }
                                        return (
                                            <span>{currentTime[0]}</span>
                                        );
                                    })
                                }
                            </p>
                            <br/>
                            <p className={'text text-inter text-s12'}>
                                {getFormattedDate()}
                            </p>
                        </div>


                    </div>

                    <div class="menu-box__body">

                        <div class="namaz-box">
                            <div class="namaz-box__head">
                                <p className={'text text-inter text-s20'}>
                                    Следующий намаз
                                </p>
                            </div>
                            {/*style={{backgroundImage: "url(" + gradient + ")"}}*/}
                            <div className={"namaz-box__body namaz-box__body-" + bkCurrent}>
                                {
                                    convertDates(allDateTimeCity).map((timeEl) => {
                                        if (!timeEl[2]) {
                                            return null;
                                        }
                                        return (
                                            <>
                                                <span className={'text text-inter text-s13 '}>{timeEl[0]}</span>
                                                <span className={'text text-inter text-s24 '}> {timeEl[2]}</span>
                                                <span className={'text text-inter text-s13 '}> {timeEl[1]}</span>
                                            </>
                                        );
                                    })
                                }
                            </div>
                            <div class="namaz-box_footer">
                                <button onClick={() => {
                                    navigate('/time');
                                }} className={'text text-inter text-s14'}>Время намаза
                                </button>
                            </div>
                        </div>

                        {
                            constryctor?.length && constryctor.map((constryctorItem) => {

                                let coors = [];
                                if (constryctorItem?.category_id_return) {
                                    coors = constryctorItem?.category_id_return?.adress ? constryctorItem.category_id_return.adress.split(',') : [];
                                }

                                return (
                                    <div className={'contruct_el'}>
                                        <p className={'text text-s20'}>
                                            <b>{constryctorItem.name}</b>
                                        </p>

                                        {
                                            constryctorItem?.category_id_return && (
                                                <>
                                                    <div className="contruct_el_body">
                                                        <div className="contruct_el-icon"
                                                             style={{backgroundImage: "url('" + (constryctorItem?.icon) + "')"}}>

                                                        </div>
                                                        <a target={'_blank'}
                                                           href={coors.length > 1 ? 'https://www.google.com/maps?q=' + coors[0] + ',' + coors[1] : false}
                                                           style={{color: "inherit", textDecoration: "none"}}
                                                           className="contruct_el_content">
                                                            <b className={'text text-s16'}>
                                                                {
                                                                    constryctorItem?.category_id_return.name

                                                                }
                                                            </b>
                                                            <p className={'contruct_el_content_con text text-s12'}>
                                                                <img style={{width: "1.2rem", height: "1.2rem"}} src={point}
                                                                     alt={''}/>
                                                                <span>
                                                        {
                                                            constryctorItem?.category_id_return.adress_name
                                                        }
                                                    </span>
                                                            </p>


                                                        </a>

                                                        <b className={'icon-right-box text text-s12'}>
                                                            {constryctorItem?.category_id_return?.distations} км <img
                                                            className={'icon-right'}
                                                            style={{width: "1.2rem", height: "1.2rem"}}
                                                            src={right}
                                                            alt={''}/>
                                                        </b>


                                                    </div>

                                                    {
                                                        constryctorItem?.category_id_return?.category_id_info?.name && (
                                                            <div className="namaz-box_footer">
                                                                <button onClick={() => {
                                                                    navigate('/category/' + (constryctorItem?.category_id_return?.category_id_info?.id));
                                                                }}
                                                                        className={'text text-inter text-s14'}>Все {getLanguage(constryctorItem?.category_id_return?.category_id_info?.name)}
                                                                </button>
                                                            </div>
                                                        )
                                                    }

                                                </>
                                            )
                                        }


                                        {
                                            !(constryctorItem?.category_id_return) && (
                                                <div>
                                                    <p className={'text text-s14'}
                                                       style={{color: "#000", opacity: "0.5", margin: "0.5rem 0"}}>
                                                        {
                                                            constryctorItem?.time_article
                                                        }
                                                    </p>
                                                    <p className={'text text-s15'}
                                                       style={{color: "#000", margin: "0.5rem 0"}}>
                                                        <b>  {
                                                            constryctorItem?.name_title
                                                        }</b>
                                                    </p>
                                                    <button onClick={() => {

                                                        if (constryctorItem?.article_return) {
                                                            navigate('/article/' + constryctorItem?.article_return?.id);
                                                        }


                                                    }} style={{textAlign: "left"}} className={'text text-s15'}
                                                            dangerouslySetInnerHTML={{__html: constryctorItem?.content}}>

                                                    </button>

                                                    <div className="namaz-box_footer">
                                                        <button onClick={() => {
                                                            if (constryctorItem?.article_return?.article_catalog_id) {
                                                                navigate('/articles/' + constryctorItem?.article_return?.article_catalog_id);
                                                            }
                                                        }} className={'text text-inter text-s14'}>Все статьи
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                )
                            })
                        }


                    </div>

                </div>

                <TapBar active={true}/>
                <Outlet/>

            </div>
        </div>
    );

}