import './scss/index.scss';
import {Outlet, useNavigate, useParams} from "react-router-dom";


import TapBar from "../../components/tapBar";


import StateSlices from "../../redux/slices/State";
import {useSelector} from "react-redux";
import StateActions from "../../redux/actions/StateActions";
import {useEffect, useState} from "react";
import {getArticles, getSingle} from "../../services";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const {id} = useParams();

    const navigate = useNavigate();

    const [ariclesParent, setAriclesParent] = useState({});

    function getLanguage(inputString) {
        try {
            const jsonObj = JSON.parse(inputString);
            if (jsonObj.lang && jsonObj.lang.ru) {
                return jsonObj.lang.ru;
            } else {
                return inputString;
            }
        } catch (error) {
            return inputString;
        }
    }

    async function getArticlesAll() {

        let getArticlesCatalog = (await getSingle('Article', id, '?lang=ru')).data;

        setAriclesParent(getArticlesCatalog);
    }

    useEffect(() => {
        getArticlesAll();
    }, []);

    return (
        <div className={'page page-menu'}>
            <div className="page-menu-content">

                <div style={{boxSizing: "border-box", padding: "2rem 1.4rem"}}>
                    <p className={'text text-s15'}
                       style={{color: "#000", margin: "0.5rem 0"}}>
                        <b>{getLanguage(ariclesParent?.name)}</b>
                    </p>
                    <p className={'text text-s15'} style={{whiteSpace: "pre-wrap"}}
                       dangerouslySetInnerHTML={{__html: getLanguage(ariclesParent?.content)}}>

                    </p>
                </div>

                <TapBar back={()=>{
                    navigate('/menu');
                }} active={true}/>
                <Outlet/>

            </div>
        </div>
    );

}