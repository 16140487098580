import './scss/index.scss';

// @ts-ignore
import compass from "./assets/icon/compass.svg";
// @ts-ignore
import left from "./assets/icon/left.svg";
// @ts-ignore
import halalorg from "./assets/icon/halalorg.svg";
import {useNavigate} from "react-router-dom";

interface Props {
    active: string;
    back: Function;
}

const tapBar: React.FC<Props> = ({active = false, back}) => {


    const navigate = useNavigate();

    return (
        <div className={'tap-bar ' + (active && 'active')}>
            <div className="tap-bar__el">
                <button onClick={() => {
                    navigate('/compos');
                }} className={'tap-bar-link'} style={{maskImage: "url(" + compass + ")"}}></button>
            </div>
            <div onClick={() => {
                navigate('/time');
            }} className="tap-bar__el" style={{justifyContent: "center"}}>
                <button className={'tap-bar-link'} style={{maskImage: "url(" + halalorg + ")"}}></button>
            </div>
            <div className="tap-bar__el" style={{justifyContent: "flex-end"}}>
                <button onClick={() => {
                    if (back) {
                        back();
                    } else {
                        if (active) {
                            navigate('/time');
                        } else {
                            navigate('/menu');
                        }
                    }
                }} className={'tap-bar-btn text text-inter text-s12'}
                        style={{backgroundImage: "url(" + halalorg + ")"}}>

                    {
                        !active && (
                            'МЕНЮ'
                        )
                    }
                    {
                        active && (
                            <img className={'icon-left-mini'} src={left}/>
                        )
                    }

                </button>
            </div>
        </div>
    )
        ;

}

export default tapBar;