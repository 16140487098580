import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

// @ts-ignore
import Bk from './assets/img/main.png';
import CitySelectModal from '../../components/citySelectModal';
import {SetStateAction, useEffect, useState} from "react";


import {getAllCity} from "../../services";

import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);
    const [cityModal, setCityModal] = useState(false);
    const [searchCity, setSearchCity] = useState(false);
    const [userName, setUserName] = useState('');
    const [cities, setCities] = useState([]);
    const [citySelect, setCitySelect] = useState({
        name: ""
    });

    async function getCity() {
        const cityDate = (await getAllCity()).data
        setCities(cityDate);
    }

    useEffect(() => {
        getCity().then();
    }, [])

    const navigate = useNavigate();

    return (
        <div className={'page-city'}>

            {
                state_in?.userName == '' && (
                    <div className="form-name-background">
                        <div className={'form-name'}>
                            <p className={'text text-s14'}>
                                Введите ваше имя
                            </p>
                            <input name={''} onChange={(e) => {

                                setUserName(e.target.value);

                            }} value={userName} className={'text text-s14'} placeholder={'Ваше имя'}/>

                            <button onClick={() => {
                                if (userName?.length  && userName.length > 3) {
                                    State({
                                        userName: userName
                                    });
                                }

                            }} className={'btn-on text text-s14'}>
                                Подтвердить
                            </button>
                        </div>
                    </div>
                )
            }

            <CitySelectModal
                onChange={(e) => {
                    setCitySelect(e);
                    State({
                        city_select: e
                    });
                    setCityModal(false);

                }} cities={cities.filter((city) => {
                return searchCity ? city?.name && city.name.includes(searchCity) : true;
            })} status={cityModal} onStatus={() => {
                setCityModal(false);
            }}
                onSearch={(e) => {
                    setSearchCity(e.target.value);
                }}
            />

            <div className="page-city__center">
                <img src={Bk} className={'page-city__img'} alt={'im'}/>
                <p className={'text text-s36'}>
                    iMuslim
                </p>
                <div className="page-city_btns">
                    <button onClick={() => {
                        setCityModal(true);
                    }} className={'btn-city text text-s16'}>
                        {citySelect?.name ? citySelect?.name : "Выбрать город"}
                    </button>
                </div>


                {
                    citySelect?.name && (
                        <div className="page-city_btns">
                            <button onClick={() => {
                                navigate('/time');
                            }} className={'btn-link text text-s16'}>
                                {"Продолжить"}
                            </button>
                        </div>
                    )
                }
                <Outlet/>
            </div>
        </div>
    );


}