import './scss/index.scss';
import {Outlet, useNavigate} from "react-router-dom";


import TapBar from "../../components/tapBar";


import StateSlices from "../../redux/slices/State";
import {useSelector} from "react-redux";
import StateActions from "../../redux/actions/StateActions";
import {useEffect, useState} from "react";

import {useParams} from 'react-router-dom';
import {getArticles, getCategoryItem, getSingle} from "../../services";
import point from "../menu/assets/icon/point.svg";
import right from "../menu/assets/icon/right.svg";
import State from "../../redux/actions/StateActions";

export default function AboutRoute() {

    const state_in = useSelector(StateSlices);
    const citySelect = (state_in.city_select);

    const {id} = useParams();


    const navigate = useNavigate();

    const [articles, setArticles] = useState([]);
    const [ariclesParent, setAriclesParent] = useState({});

    function getLanguage(inputString) {
        try {
            const jsonObj = JSON.parse(inputString);
            if (jsonObj.lang) {
                return jsonObj.lang?.ru;
            } else {
                return inputString;
            }
        } catch (error) {
            return inputString;
        }
    }

    async function getArticlesAll() {


        let allArticles = (await getCategoryItem(id, citySelect?.id, state_in.lat, state_in.long)).data;


        let getArticlesCatalog = (await getSingle('Category', id, '?lang=ru')).data;

        setAriclesParent(getArticlesCatalog);
        setArticles(allArticles);
    }

    useEffect(() => {

        getArticlesAll().then();

    }, []);

    return (
        <div className={'page page-menu'}>
            <div className="page-menu-content" style={{padding: "3rem 1.4rem", paddingBottom: "6rem"}}>

                <b style={{display: "flex", alignItems: "center", marginBottom: "-1rem"}} className={'text text-s18'}>
                    {
                        getLanguage(ariclesParent?.name)
                    }
                </b>
                {
                    articles.map((constryctorItem) => {

                        const coors = constryctorItem?.adress ? constryctorItem.adress.split(',') : [];
                        return (
                            <div className="contruct_el_body">
                                <div className="contruct_el-icon"
                                     style={{backgroundImage: "url('https://imuslim.kz/" + (ariclesParent?.image) + "')"}}>

                                </div>
                                <a target={'_blank'} style={{color: "inherit", textDecoration: "none"}}
                                   href={coors.length > 1 ? 'https://www.google.com/maps?q=' + coors[0] + ',' + coors[1] : false}
                                   className="contruct_el_content">
                                    <b className={'text text-s16'}>
                                        {
                                            getLanguage(constryctorItem?.name)
                                        }
                                    </b>
                                    <p className={'contruct_el_content_con text text-s12'}>
                                        <img style={{width: "1.2rem", height: "1.2rem"}} src={point}
                                             alt={''}/>
                                        <span>
                                                        {
                                                            getLanguage(constryctorItem?.adress_name)
                                                        }
                                                    </span>
                                    </p>


                                </a>

                                <b className={'icon-right-box text text-s12'}>
                                    {constryctorItem?.distations} км <img className={'icon-right'}
                                                                          style={{width: "1.2rem", height: "1.2rem"}}
                                                                          src={right}
                                                                          alt={''}/>
                                </b>


                            </div>
                        );
                    })
                }

                <TapBar back={() => {
                    navigate('/menu');
                }} active={true}/>
                <Outlet/>

            </div>
        </div>
    );

}