import './scss/index.css';
import Modal from "react-modal";
import InputCustom from "../inputCustom";


interface Props {
    status: boolean;
    cities?: Array<any>;
    onStatus?: Function;
    onSearch?: Function;
    onChange?: Function;
}


// eslint-disable-next-line react/prop-types
const Empty: React.FC<Props> = ({status, onChange, onSearch, onStatus, cities = []}) => {


    return (
        <>
            <Modal
                isOpen={status}
                onRequestClose={() => {
                    if (onStatus) {
                        onStatus(false);
                    }
                }}
                contentLabel="Example Modal"
                className="model-city"
            >
                <InputCustom onChange={(e: any) => {
                    if (onSearch) {
                        onSearch(e);
                    }
                }} ws={-1} name={'Поиск города'}/>

                <div className={'city-list'}>
                    {
                        cities.map((city) => {
                            return (
                                <button onClick={() => {
                                    if (onChange) {
                                        onChange(city);
                                    }
                                }} className={'city-list__el text text-s16'}>
                                    {city.name}
                                </button>
                            );
                        })
                    }
                </div>
            </Modal>

        </>
    );

}

export default Empty;