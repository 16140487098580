import './scss/index.scss';
import {Outlet, useNavigate} from "react-router-dom";


import TapBar from "../../components/tapBar";


import StateSlices from "../../redux/slices/State";
import {useSelector} from "react-redux";
import StateActions from "../../redux/actions/StateActions";
import {useEffect, useState} from "react";

import {useParams} from 'react-router-dom';
import {getArticles, getSingle} from "../../services";
import point from "../menu/assets/icon/point.svg";
import right from "../menu/assets/icon/right.svg";
import State from "../../redux/actions/StateActions";

export default function AboutRoute() {

    const state_in = useSelector(StateSlices);
    const citySelect = (state_in.city_select);

    const {id} = useParams();


    const navigate = useNavigate();

    const [articles, setArticles] = useState([]);
    const [ariclesParent, setAriclesParent] = useState({});

    function getLanguage(inputString) {
        try {
            const jsonObj = JSON.parse(inputString);
            if (jsonObj.lang && jsonObj.lang.ru) {
                return jsonObj.lang.ru;
            } else {
                return inputString;
            }
        } catch (error) {
            return inputString;
        }
    }

    async function getArticlesAll() {
        let allArticles = (await getArticles(id)).data;
        let getArticlesCatalog = (await getSingle('ArticleCatalog', id, '?lang=ru')).data;

        setAriclesParent(getArticlesCatalog);
        setArticles(allArticles);
    }

    useEffect(() => {

        getArticlesAll().then();

    }, []);

    return (
        <div className={'page page-menu'}>
            <div className="page-menu-content" style={{padding: "3rem 1.4rem", paddingBottom: "6rem"}}>

                <b style={{display: "flex", alignItems: "center", marginBottom: "-1rem"}} className={'text text-s18'}>
                    <img style={{width: "2rem", marginRight: "1rem"}}
                         src={'https://imuslim.kz/' + ariclesParent?.images}/>
                    {
                        getLanguage(ariclesParent?.name)
                    }
                </b>
                {
                    articles.map((constryctorItem) => {
                        return (
                            <div className={'contruct_el'}>
                                {
                                    (
                                        <div>
                                            <p className={'text text-s15'}
                                               style={{color: "#000", margin: "0.5rem 0"}}>
                                                <b>  {
                                                    constryctorItem?.name
                                                }</b>
                                            </p>
                                            <button onClick={() => {
                                                navigate('/article/' + constryctorItem?.id);
                                            }} style={{textAlign: "left"}} className={'text text-s15'}
                                                    dangerouslySetInnerHTML={{__html: constryctorItem?.content}}>

                                            </button>
                                        </div>
                                    )
                                }

                            </div>
                        );
                    })
                }

                <TapBar back={() => {
                    navigate('/menu');
                }} active={true}/>
                <Outlet/>

            </div>
        </div>
    );

}