import axios from 'axios';

// eslint-disable-next-line no-undef
const {REACT_APP_API_PATH} = process.env;


async function request(request) {

    request = Object.assign({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        method: 'GET',
        url: '',
    }, request);

    request.url = REACT_APP_API_PATH + request.url;
    return await axios(request).then((response) => {
        return response.data;
    }).catch(function () {

        return [];
    });
}


export const getAllDistantionAdress = async () => {

    const req = (await request({
        url: '/order/maps/list',
        method: 'GET',
    }));

    return req;
}

export const conConstGet = async (city_id, lat, long) => {

    const req = (await request({
        url: '/const/get?city_id=' + city_id + '&lang=ru&location={"latitude":' + lat + ',"longitude":' + long + '}',
        method: 'GET',
    }));

    return req;
}
export const getAllCity = async () => {

    const req = (await request({
        url: '/database/get/City?type=json&v=2&lang=ru',
        method: 'GET',
    }));

    return req;
}

export const getSingle = async (modal, id, params = '') => {

    const req = (await request({
        url: `/database/get/${modal}/${id}` + params,
        method: 'GET',
    }));
    return req;
}

export const getArticles = async (id) => {

    const req = (await request({
        url: `/database/get/Article?lang=ru&article_catalog_id=` + id,
        method: 'GET',
    }));
    return req;
}

export const getCategoryItem = async (id, city_id, lat, long) => {

    const req = (await request({
        url: `/database/get/CategoryItem?lang=ru&catalog_id=` + id + '&city_id=' + city_id + '&lang=ru&location={"latitude":' + lat + ',"longitude":' + long + '}',
        method: 'GET',
    }));
    return req;
}


export const getBG = async () => {

    const req = (await request({
        url: '/database/get/ImgBack',
        method: 'GET',
    }));

    return req;
}

export const getAllDateTime = async (cityId) => {

    const req = (await request({
        url: '/country/' + cityId + '/all?v=2',
        method: 'GET',
    }));

    return req;
}